import { Component, Input } from "@angular/core";
import { CounterService } from "../services/counter.service";
import { PricesService } from "../services/prices.service";

@Component({
  selector: "zafe-price-table",
  templateUrl: "./price-table.component.html",
  styleUrls: ["./price-table.component.scss"],
})
export class PriceTableComponent {
  @Input() members = 1;
  @Input() type!: string;

  @Input() cantidad = 0;
  @Input() enganche = 0;
  @Input() isCounted = true;

  constructor(
    private readonly counterService: CounterService,
    private readonly pricesService: PricesService
  ) {}

  get cantidadIva() {
    return Math.floor(this.cantidad);
  }

  get descuento() {
    return Math.floor(this.pricesService.negativo(this.cantidad));
  }

  get descuentoContado() {
    const discount = this.pricesService.getPriceByType(this.type);

    return Math.floor(
      this.isCounted
        ? this.pricesService.negativo(
            this.cantidad - discount[this.counterService.count - 1]
          )
        : this.pricesService.negativo(0)
    );
  }

  get subtotal() {
    return Math.floor(
      this.isCounted ? this.cantidad + this.descuentoContado : this.cantidad
    );
  }

  get iva() {
    return Math.floor(this.subtotal * 0.16);
  }

  get engancheFinal() {
    return Math.floor(this.pricesService.negativo(this.enganche));
  }

  get cantidadEnganche() {
    return Math.floor(this.cantidadMSI / 1.16);
  }

  get cantidadMSI() {
    return Math.floor(this.cantidadIva - this.enganche);
  }

  get total() {
    return Math.floor(this.subtotal);
  }

  get membersPrice() {
    return (
      (this.isCounted ? this.cantidad : this.cantidadEnganche) / this.members
    );
  }
}
