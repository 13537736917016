import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import Swal from "sweetalert2";

import { AuthService } from "./core/services/auth.service";

import { environment } from "../environments/environment";
import { UpdatePromptService } from "./core/services/update-prompt.service";

@Component({
  selector: "zafe-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private _isLogout = false;
  private _isHome = false;
  private _isBack = false;

  public validateVersion = true;
  public isUpdateAvailable = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly updatePromptService: UpdatePromptService
  ) {}

  ngOnInit(): void {
    this.updatePromptService.update().subscribe({
      next: (evt) => {
        if (evt.type === "VERSION_READY") {
          this.isUpdateAvailable = true;
        }

        this.validateVersion = false;
      },
    });

    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        if (this.router.url === "/welcome" || this.router.url === "/success") {
          this.isLogout = true;
        } else {
          this.isLogout = false;
        }

        if (
          this.router.url === "/validate/box" ||
          this.router.url === "/payment"
        ) {
          this.isHome = true;
        } else {
          this.isHome = false;
        }

        if (this.router.url === "/register") {
          this.isBack = true;
        } else {
          this.isBack = false;
        }
      }
    });
  }

  cancelSale() {
    Swal.fire({
      title: "¿Estás Seguro?",
      html: `
      Toda la venta se perderá y no podrás retomarla de ninguna forma!
      <br/> 
      Si el cobro ya fue realizado, la devolución no se hará en automático, deberás notificar este incidente a la <b>administración de zafe</b>
      `,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        const user = localStorage.getItem("user");

        localStorage.clear();

        localStorage.setItem("user", user!);

        this.router.navigateByUrl("/");
      }
    });
  }

  async logout() {
    const result = await Swal.fire({
      title: "¿Estás Seguro?",
      html: `Estas apunto de cerrar la sesión, por lo que todo el acceso a la aplicación se perdera hasta que inicies nuevamente con tu cuenta`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
    });

    if (result.isConfirmed) {
      try {
        await this.authService.logout();

        localStorage.clear();

        this.router.navigateByUrl("/");

        location.reload();
      } catch (error) {
        Swal.fire("Error", "Algo salio mal, intenta nuevamente", "error");
      }
    }
  }

  set isLogout(value: boolean) {
    this._isLogout = value;
  }

  get isLogout() {
    return this._isLogout;
  }

  set isHome(value: boolean) {
    this._isHome = value;
  }

  get isHome() {
    return this._isHome;
  }

  set isBack(value: boolean) {
    this._isBack = value;
  }

  get isBack() {
    return this._isBack;
  }

  get members() {
    return localStorage.getItem("members");
  }

  get version() {
    return `${environment.version}`;
  }

  get sandbox() {
    return environment.sandbox;
  }
}
