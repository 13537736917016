<div class="row mb-5 align-items-center">
  <div class="col-12">
    <button
      class="btn btn-dark fw-bold w-auto"
      type="button"
      data-bs-toggle="modal"
      [attr.data-bs-target]="'#staticQrBackdrop' + index"
      (click)="active($event, index)"
    >
      Escanea una tarjeta zafe <i class="bi bi-qr-code-scan"></i>
    </button>
  </div>

  <div *ngIf="code" class="text-success mb-4" role="alert">
    Tarjeta escaneada correctamente
  </div>
  <div *ngIf="!code" class="text-danger fw-bold mb-4" role="alert">
    No se ha escaneado ninguna tarjeta
  </div>

  <div
    class="modal fade"
    [attr.id]="'staticQrBackdrop' + index"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticQrBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticQrBackdropLabel">
            Escanear QR
          </h1>
          <button
            [attr.id]="'scanbutton' + index"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="desactive(index)"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <b>Preferred camera:</b>
            <select id="cam-list">
              <option value="environment" selected>
                Environment Facing (default)
              </option>
              <option value="user">User Facing</option>
            </select>
          </div>

          <!-- <div id="video-container"> -->
          <div class="video-container" [attr.id]="'video-container' + index">
            <!-- <video id="qr-video"> -->

            <video class="qr-video" [attr.id]="'qr-video' + index"></video>
          </div>

          <div *ngIf="error != ''" class="alert alert-danger" role="alert">
            {{ error }}
          </div>

          <div
            *ngIf="code != ''"
            class="alert alert-success fw-bold"
            role="alert"
          >
            Tarjeta valida
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
