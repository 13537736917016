<div>
  <button
    [disabled]="isDecrement"
    class="btn btn-primary fw-bold p-2"
    (click)="increment(-1)"
  >
    -
  </button>

  <span class="fw-bold fs-5 mx-4">{{ count }}</span>

  <button
    [disabled]="isIncrement"
    class="btn btn-primary fw-bold p-2"
    (click)="increment(1)"
  >
    +
  </button>
</div>
