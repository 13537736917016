import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CounterService {
  private _count: number = 1;
  private _countCards: number = 0;

  constructor() {}

  set count(value: number) {
    this._count = value;
  }

  get count() {
    return this._count;
  }

  set countCards(value: number) {
    this._countCards = value;
  }

  get countCards() {
    return this._countCards;
  }
}
