import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CounterService } from "../services/counter.service";
import { PricesService } from "../services/prices.service";

@Component({
  selector: "zafe-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
})
export class CounterComponent implements OnInit {
  @Input() lowerLimit = 1;
  @Input() limit = 10;
  @Input() type = "anual";

  @Output() clicked = new EventEmitter();

  constructor(
    private readonly counterService: CounterService,
    private readonly pricesService: PricesService
  ) {}

  ngOnInit(): void {
    localStorage.setItem("membersCount", `1`);
    localStorage.setItem("cardsCount", `0`);
  }

  increment(value: number) {
    this.clicked.emit(value >= 1 ? "increment" : "decrement");

    if (this.lowerLimit === 1) {
      if (
        this.counterService.count + value <= this.lowerLimit - 1 ||
        this.counterService.count + value > this.limit
      ) {
        return;
      }

      this.counterService.count += value;

      localStorage.setItem("membersCount", `${this.counterService.count}`);
    } else {
      if (
        this.counterService.countCards + value <= this.lowerLimit - 1 ||
        this.counterService.countCards + value > this.counterService.count ||
        this.counterService.countCards + value > this.limit
      ) {
        return;
      }

      this.counterService.countCards += value;

      localStorage.setItem("cardsCount", `${this.counterService.countCards}`);
    }

    this.pricesService.setPrice(this.type === "mensual" ? "mensual" : "anual");
  }

  get isIncrement() {
    return this.lowerLimit !== 1
      ? this.counterService.countCards + 1 > this.counterService.count ||
          this.counterService.countCards >= this.limit
      : this.counterService.count >= this.limit;
  }

  get isDecrement() {
    return this.lowerLimit !== 1
      ? this.counterService.countCards <= this.lowerLimit
      : this.counterService.count <= this.lowerLimit;
  }

  get count() {
    return this.lowerLimit !== 1
      ? this.counterService.countCards
      : this.counterService.count;
  }
}
