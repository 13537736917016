import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { SalesService } from "./../../../../core/services/sales.service";

@Component({
  selector: "zafe-pending",
  templateUrl: "./pending.component.html",
  styleUrls: ["./pending.component.scss"],
})
export class PendingComponent implements OnInit {
  private _pendingAppointment: any[] = [];

  constructor(
    private readonly salesService: SalesService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getPendingAppointment();
  }

  getPendingAppointment() {
    this.salesService.getPendingAppointments().subscribe((res) => {
      this._pendingAppointment = res;
    });
  }

  continuePendingSale(id: string) {
    localStorage.setItem("saleId", id);
    this.router.navigateByUrl("/validate/box");
  }

  get pendingAppointment() {
    return this._pendingAppointment;
  }
}
