import { Injectable } from "@angular/core";
import { CounterService } from "./counter.service";

@Injectable({
  providedIn: "root",
})
export class PricesService {
  private _prices = [
    15750, 15750, 26250, 36750, 47250, 57750, 68250, 78750, 89250, 99750,
  ];
  private _price = this._prices[0];

  constructor(private readonly counterService: CounterService) {}

  negativo(value: number) {
    return value * -1;
  }

  setPrice(type: string = "anual") {
    if (type === "anual") {
      this._prices = [
        15750, 15750, 26250, 36750, 47250, 57750, 68250, 78750, 89250, 99750,
      ];
    }

    if (type === "mensual") {
      this._prices = [
        2040, 2040, 3400, 4760, 6120, 7480, 8840, 10200, 11560, 12920,
      ];
    }

    if (type === "anual_hoy") {
      this._prices = [
        10500, 21000, 31500, 42000, 52500, 63000, 73500, 84000, 94500, 105000,
      ];
    }

    this._price = localStorage.getItem("subtotal")
      ? this._prices[this.counterService.count - 1]
      : this._prices[0];

    const subtotal = this._price;

    const total = Math.round(subtotal);

    localStorage.setItem("subtotal", `${subtotal}`);
    localStorage.setItem("total", `${total}`);
  }

  get enganche() {
    return JSON.parse(localStorage.getItem("enganche") || "0");
  }

  getPrice() {
    return this._price;
  }

  getPriceByType(type: "anual" | "anual_hoy" | "mensual" | string) {
    if (type === "anual_hoy") {
      return [
        10500, 21000, 31500, 42000, 52500, 63000, 73500, 84000, 94500, 105000,
      ];
    }

    if (type === "mensual") {
      return [2040, 2040, 3400, 4760, 6120, 7480, 8840, 10200, 11560, 12920];
    }

    return [
      15750, 15750, 26250, 36750, 47250, 57750, 68250, 78750, 89250, 99750,
    ];
  }

  get price() {
    return this.getPrice();
  }

  get subtotal() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return JSON.parse(localStorage.getItem("subtotal")!);
  }

  get total() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return JSON.parse(localStorage.getItem("total")!);
  }
}
