import { Component, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "zafe-roles-modal",
  templateUrl: "./roles-modal.component.html",
  styleUrls: ["./roles-modal.component.scss"],
})
export class RolesModalComponent implements OnInit {
  @Input() index: number = 0;
  @Input() isMinor: boolean = false;
  roles: any[] = [];
  role: string = "";

  constructor(private readonly httpClient: HttpClient) {}

  ngOnInit(): void {
    let membershipType = "";
    let group = JSON.parse(localStorage.getItem("group")!);

    if (group.type == "Corporativo") {
      membershipType = "group";
    }

    if (group.type == "Familiar") {
      membershipType = "familiar";
    }

    if (group.type == "Amigos") {
      membershipType = "friend";
    }

    this.httpClient
      .get<any>(
        `https://us-central1-trust-11b7d.cloudfunctions.net/registerCircle/roles?membershipType=${membershipType}`
      )
      .subscribe(({ roles }) => {
        this.roles = roles;
      });
  }

  selectRole(role: any) {
    localStorage.setItem(
      this.isMinor ? `minorRole${this.index}` : `role${this.index}`,
      role.id
    );

    this.role = role.name;
  }

  get name() {
    return this.isMinor ? "Minor" + this.index : this.index;
  }
}
