<table class="table table-striped table-hover">
  <thead class="bg-secondary text-white">
    <th>Concepto</th>
    <th>Cantidad</th>
  </thead>
  <tbody>
    <tr>
      <th>Membresías</th>
      <td>
        {{ cantidad | currency: "MXN" }}
      </td>
    </tr>
    <tr *ngIf="isCounted">
      <th>Descuento de Primera Visita</th>
      <td class="text-info fw-bold">
        {{ descuentoContado | currency: "MXN" }}
      </td>
    </tr>
    <tr>
      <th>Subtotal</th>
      <td>{{ subtotal | currency: "MXN" }}</td>
    </tr>
    <tr>
      <th>IVA ({{ 0.16 | percent }})</th>
      <td>{{ iva | currency: "MXN" }}</td>
    </tr>
    <tr>
      <th>Total</th>
      <td class="fw-bold text-primary">{{ total | currency: "MXN" }}</td>
    </tr>
  </tbody>
</table>
