import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "apps/onboarding/src/environments/environment";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  private readonly _base = `${environment.cloudFunctionsURL}/server/referrer`;
  private readonly _isSandbox = environment.sandbox;

  constructor(private readonly httpClient: HttpClient) {}

  getLastAppointments() {
    return this.httpClient
      .get(`${this._base}/all?test=${this._isSandbox}`)
      .pipe(map((res: any) => res.referrers));
  }

  getPendingAppointments() {
    return this.httpClient
      .get(`${this._base}/pending?test=${this._isSandbox}`)
      .pipe(map((res: any) => res.referrers));
  }

  searchAppointments(search: string) {
    return this.httpClient
      .get(
        `${this._base}/search?q=${search.trim().toLowerCase()}&test=${
          this._isSandbox
        }`
      )
      .pipe(map((res: any) => res.referrers));
  }

  changeAppointmentStatusPending(id: string) {
    return this.httpClient.put(`${this._base}/pending/${id}`, null);
  }

  changeAppointmentStatusConfirmed(id: string) {
    return this.httpClient.put(`${this._base}/confirmed/${id}`, null);
  }
}
