import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@zafe/onboarding/auth";

@Component({
  selector: "zafe-google-button-small",
  templateUrl: "./google-button-small.component.html",
  styleUrls: ["./google-button-small.component.scss"],
})
export class GoogleButtonSmallComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  loginWithGoogle() {
    this.authService
      .loginWithGoogle()
      .then(({ user }) => {
        this.authService.verifyZafers(user.uid, user);
      })
      .catch((error) => {
        this.authService.logout().finally(() => {
          this.authService.clearUser();
          this.router.navigate(["/"]);
        });
      });
  }
}
