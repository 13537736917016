import { Component } from "@angular/core";

@Component({
  selector: "zafe-update-prompt",
  templateUrl: "./update-prompt.component.html",
  styleUrls: ["./update-prompt.component.scss"],
})
export class UpdatePromptComponent {
  reload() {
    document.location.reload();
  }
}
