import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable({
  providedIn: "root",
})
export class UpdatePromptService {
  constructor(private readonly swUpdate: SwUpdate) {}

  update() {
    return this.swUpdate.versionUpdates;
  }
}
