import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { ZXingScannerModule } from "@zxing/ngx-scanner";

import { LoaderComponent } from "./loader/loader.component";
import { CounterComponent } from "./counter/counter.component";
import { PriceTableComponent } from "./price-table/price-table.component";
import { AvatarModalComponent } from "./avatar-modal/avatar-modal.component";
import { RolesModalComponent } from "./roles-modal/roles-modal.component";
import { QrModalComponent } from "./qr-modal/qr-modal.component";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, HttpClientModule, ZXingScannerModule],
  declarations: [
    LoaderComponent,
    CounterComponent,
    PriceTableComponent,
    AvatarModalComponent,
    RolesModalComponent,
    QrModalComponent,
  ],
  exports: [
    LoaderComponent,
    CounterComponent,
    PriceTableComponent,
    AvatarModalComponent,
    RolesModalComponent,
    QrModalComponent,
  ],
})
export class SharedUiModule {}
