import {
  canActivate,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { Route } from "@angular/router";

import { LoginDumbComponent } from "@zafe/auth";

export const appRouter: Route[] = [
  {
    path: "",
    ...canActivate(() => redirectLoggedInTo(["/welcome"])),
    component: LoginDumbComponent,
  },
  {
    path: "",
    pathMatch: "prefix",
    ...canActivate(() => redirectUnauthorizedTo(["/"])),
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
];
