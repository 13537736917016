import { Component, Input } from "@angular/core";
import QrScanner from "qr-scanner";

@Component({
  selector: "zafe-qr-modal",
  templateUrl: "./qr-modal.component.html",
  styleUrls: ["./qr-modal.component.scss"],
})
export class QrModalComponent {
  @Input() index: number = 0;
  @Input() quantity: number = 0;

  private _active: boolean = false;

  error: string = "";
  code: string = "";

  cameras: MediaDeviceInfo[] = [];
  myDevice!: MediaDeviceInfo;
  scannerEnabled = false;

  video: any = document.getElementById(`qr-video${this.index}`);
  videoContainer: any = document.getElementById(`video-container${this.index}`);
  camHasCamera: any = document.getElementById("cam-has-camera");
  camList: any = document.getElementById("cam-list");
  camHasFlash: any = document.getElementById("cam-has-flash");
  flashToggle: any = document.getElementById("flash-toggle");
  flashState: any = document.getElementById("flash-state");
  camQrResult: any = document.getElementById("cam-qr-result");
  camQrResultTimestamp: any = document.getElementById(
    "cam-qr-result-timestamp"
  );
  fileSelector: any = document.getElementById("file-selector");
  fileQrResult: any = document.getElementById("file-qr-result");

  scanner: any;

  constructor() {
    /* este código necesita revisión, habilitar sólo para probar 
    la laectura de qr limpiando el caché */
    //this.clearCodeStorage();
  }

  clearCodeStorage() {
    /* sólo para pruebas y test, remover el caché */
    const maximumusers = 10;

    for (let index = 0; index < maximumusers; index++) {
      localStorage.removeItem(`cardCode${index}`);
    }
  }

  ngOnInit(): void {}

  init() {
    /* Si el usuario abre de nuevo esta opción es porque quiere cambiar la tarjeta, así que 
    vamos a forzarlo a que lea de nuevo, ya sea la misma o una diferente */
    this.code = "";
    this.error = "";
    localStorage.removeItem(`cardCode${this.index}`);

    this.video = document.getElementById(`qr-video${this.index}`);
    this.videoContainer = document.getElementById(
      `video-container${this.index}`
    );
    this.camHasCamera = document.getElementById("cam-has-camera");
    this.camList = document.getElementById("cam-list");
    this.camHasFlash = document.getElementById("cam-has-flash");
    this.flashToggle = document.getElementById("flash-toggle");
    this.flashState = document.getElementById("flash-state");
    this.camQrResult = document.getElementById("cam-qr-result");
    this.camQrResultTimestamp = document.getElementById(
      "cam-qr-result-timestamp"
    );
    this.fileSelector = document.getElementById("file-selector");
    this.fileQrResult = document.getElementById("file-qr-result");

    this.scanner = new QrScanner(
      this.video,
      async (result) => {
        if (this.code == "") await this.setResult(this.camQrResult, result);
      },
      {
        onDecodeError: (error) => {
          /* this.camQrResult.textContent = error; */
          /* this.camQrResult.style.color = "inherit"; */
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );

    this.scanner.setInversionMode("both");

    //this.scanner = scanner;

    const updateFlashAvailability = () => {
      this.scanner.hasFlash().then((hasFlash: boolean) => {
        this.camHasFlash.textContent = hasFlash;
        this.flashToggle.style.display = hasFlash ? "inline-block" : "none";
      });
    };

    this.scanner.start().then(() => {
      /* updateFlashAvailability(); */
      // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
      // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
      // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
      // start the scanner earlier.
      QrScanner.listCameras(true).then((cameras) =>
        cameras.forEach((camera) => {
          const option = document.createElement("option");
          option.value = camera.id;
          option.text = camera.label;
          this.camList.add(option);
        })
      );
    });

    const this_ = this;
    /* let scanregion = document.getElementById(
      "scan-region-highlight-style-select"
    );

    scanregion?.addEventListener("change", (e: any) => {
      this_.videoContainer.className = e.target.value;
      this.scanner._updateOverlay(); // reposition the highlight because style 2 sets position: relative
    }); */

    document
      .getElementById("show-scan-region")
      ?.addEventListener("change", (e: any) => {
        const input = e.target;
        const label = input.parentNode;
        label.parentNode.insertBefore(this.scanner.$canvas, label.nextSibling);
        this.scanner.$canvas.style.display = input.checked ? "block" : "none";
      });

    document
      .getElementById("inversion-mode-select")
      ?.addEventListener("change", (event: any) => {
        this.scanner.setInversionMode(event.target.value);
      });

    this.camList.addEventListener("change", (event: any) => {
      this.scanner.setCamera(event.target.value).then(updateFlashAvailability);
    });

    /* this.flashToggle.addEventListener("click", () => {
      scanner
        .toggleFlash()
        .then(
          () =>
            (this_.flashState.textContent = scanner.isFlashOn() ? "on" : "off")
        );
    }); */

    document
      .getElementById("start-button")
      ?.addEventListener("click", async () => {
        await this.scanner.start();
      });

    document
      .getElementById("stop-button")
      ?.addEventListener("click", async () => {
        await this.scanner.stop();
      });
  }

  async setResult(label: any, result: any) {
    /* label.textContent = result.data; */
    //console.log(result);
    //this.camQrResultTimestamp.textContent = new Date().toString();
    /* label.style.color = "teal"; */
    /* clearTimeout(label.highlightTimeout);
    label.highlightTimeout = setTimeout(
      () => (label.style.color = "inherit"),
      100
    ); */

    await this.scanner.stop();

    await this.scanSuccessHandler(result.data, true);
  }

  selectCamera(cameraLabel: string) {
    this.cameras.forEach((camera) => {
      if (camera.label.includes(cameraLabel)) {
        this.myDevice = camera;

        this.scannerEnabled = true;
      }
    });
  }

  camerasFoundHandler(cameras: MediaDeviceInfo[]) {
    this.cameras = cameras;
    this.selectCamera(this.cameras[0].label);
  }

  async scanSuccessHandler(event: string, invert: boolean) {
    if (this.code.trim() !== "") return;

    const paramString = event.split("?")[1];
    const searchParams: any = new URLSearchParams(paramString);

    const params = { action: "", code: "" };

    for (const param of searchParams) {
      if (param[0] == "action") params.action = param[1];

      if (param[0] == "code") params.code = param[1];
    }

    if (params.action !== "invite") {
      this.error = "Este QR no es de una tarjeta zafe";
      this.code = "";

      if (invert) {
        await this.scanner.start();
      }
      return;
    }

    for (let index = 0; index < this.quantity; index++) {
      const qr = localStorage.getItem(`cardCode${index}`);
      if (qr == params.code) {
        this.error = `Este QR ya se asignó a Miembro # ${index + 1}`;
        this.code = "";
        await this.scanner.start();
        return;
      }
    }

    this.error = "";
    this.code = params.code;

    localStorage.setItem(`cardCode${this.index}`, params.code);

    /* console.log(
      `cardCode${this.index}`,
      localStorage.getItem(`cardCode${this.index}`)
    ); */

    document.getElementById(`scanbutton${this.index}`)?.click();
  }

  active($event: any, index: number) {
    this._active = true;
    this.init();
  }

  async desactive(index: number) {
    this._active = false;
    await this.scanner.stop();
    await this.scanner.destroy();
  }

  get isActive() {
    return this._active;
  }
}
