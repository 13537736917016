import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "@zafe/onboarding/auth";

@Component({
  selector: "zafe-email-password-login-small",
  templateUrl: "./email-password-login-small.component.html",
  styleUrls: ["./email-password-login-small.component.scss"],
})
export class EmailPasswordLoginSmallComponent {
  loginForm = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    password: ["", [Validators.required, Validators.minLength(8)]],
  });

  error: string = "";

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  loginWithEmailAndPassword() {
    const { email, password } = this.loginForm.value;

    if (this.isInvalid) {
      return;
    }

    this.authService
      .loginWithEmailAndPassword(email!, password!)
      .then(({ user }) => {
        this.authService.saveUser(JSON.stringify(user));

        this.router.navigate(["/welcome"]);
      })
      .catch((error) => {
        this.error = "Correo o Contraseña Invalido";
        this.authService.logout().finally(() => {
          this.authService.clearUser();
          this.router.navigate(["/"]);
        });
      });
  }

  get isInvalid() {
    return this.loginForm.invalid && this.loginForm.touched;
  }

  get untouched() {
    return this.loginForm.untouched;
  }
}
