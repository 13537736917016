import { Directive, HostListener } from "@angular/core";
import Swal from "sweetalert2";

@Directive({
  selector: "[zafeBlockCopyActions]",
})
export class BlockCopyActionsDirective {
  constructor() {}

  private async blockNotify(title: string) {
    await Swal.fire({
      title,
      icon: "error",
      showConfirmButton: true,
      showCancelButton: false,
      timerProgressBar: true,
      timer: 1500,
    });
  }

  @HostListener("copy", ["$event"])
  blockCopy(e: any) {
    e.preventDefault();

    this.blockNotify("No puedes copiar");

    return false;
  }

  @HostListener("cut", ["$event"])
  blockCut(e: any) {
    e.preventDefault();

    this.blockNotify("No puedes cortar el texto");

    return false;
  }

  @HostListener("paste", ["$event"])
  blockPaste(e: any) {
    e.preventDefault();

    this.blockNotify("No puedes pegar texto");

    return false;
  }
}
