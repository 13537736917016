import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { LoginDumbComponent } from "./login-dumb/login-dumb.component";
import { GoogleButtonSmallComponent } from "./google-button-small/google-button-small.component";
import { EmailPasswordLoginSmallComponent } from "./email-password-login-small/email-password-login-small.component";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    LoginDumbComponent,
    GoogleButtonSmallComponent,
    EmailPasswordLoginSmallComponent,
  ],
  exports: [LoginDumbComponent],
})
export class AuthModule {}
