<form
  autocomplete="off"
  [formGroup]="loginForm"
  (ngSubmit)="loginWithEmailAndPassword()"
>
  <div class="row mb-3 align-items-center">
    <div class="col-12 col-md-2">
      <label for="email" class="col-form-label">Correo</label>
    </div>
    <div class="col-12 col-md-10">
      <input
        formControlName="email"
        type="email"
        id="email"
        class="form-control"
      />
    </div>
  </div>
  <div class="row mb-3 align-items-center">
    <div class="col-12 col-md-2">
      <label for="password" class="col-form-label">Contraseña</label>
    </div>
    <div class="col-12 col-md-10">
      <input
        formControlName="password"
        type="password"
        id="password"
        class="form-control"
      />
    </div>
  </div>

  <div *ngIf="isInvalid || error" class="alert alert-danger" role="alert">
    {{ error ? error : "Algo salio mal, verifica tus datos" }}
  </div>

  <div class="row mb-3 align-items-center">
    <div class="col-12">
      <button
        [disabled]="isInvalid || untouched"
        class="btn btn-primary fw-bold w-50"
      >
        Iniciar con Email
      </button>
    </div>
  </div>
</form>
