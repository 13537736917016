import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ListComponent } from "./components/appointments/list/list.component";
import { PendingComponent } from "./components/appointments/pending/pending.component";
import { SearchComponent } from "./components/appointments/search/search.component";
import { UpdatePromptComponent } from "./components/update-prompt/update-prompt.component";
import { BlockCopyActionsDirective } from "./directives/block-copy-actions.directive";

@NgModule({
  declarations: [
    UpdatePromptComponent,
    PendingComponent,
    ListComponent,
    SearchComponent,
    BlockCopyActionsDirective,
  ],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [
    UpdatePromptComponent,
    PendingComponent,
    ListComponent,
    SearchComponent,
    BlockCopyActionsDirective,
  ],
})
export class SharedModule {}
